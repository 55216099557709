* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  animation: onPageLoad 1s ease-in-out forwards;
  font-family: "Inter", "Noto Sans", Ubuntu, Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
}

body.clicked {
  overflow: hidden;
}

img {
  max-width: 100%;
}

h1 {
  font-size: 4rem;
}

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 1.5rem;
}

.container {
  width: 90%;
  max-width: 1440px;
  margin: 0 auto;
}

.btn-primary {
  padding: 1em 2em;
  font-weight: 700;
  background: linear-gradient(to right, #c63d81, #9b2230, #c06a58) no-repeat center/cover;
  color: #fff;
  border: none;
  outline: none;
  text-align: center;
  display: inline-block;
}
.btn-primary.mobile {
  display: none;
}

.btn-secondary {
  padding: 1em 2em;
  font-weight: 700;
  background: #222;
  color: #fff;
  border: none;
  outline: none;
  text-align: center;
  display: inline-block;
}

.py-1 {
  padding: 1em 0;
}
.py-2 {
  padding: 2em 0;
}
.py-3 {
  padding: 3em 0em;
}
.py-4 {
  padding: 4em 0;
}
.py-5 {
  padding: 5em 0;
}
.py-6 {
  padding: 6em 0em;
}

@keyframes onPageLoad {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
nav {
  background-color: #181818;
  box-shadow: 0 3px 10px rgba(255, 255, 255, 0.2);
  padding: 0.2em 0;
  width: 100%;
  position: relative;
  z-index: 1;
}
nav .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: unset;
}
nav .container ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: unset;
  list-style: none;
  transition: all 0.5s ease-in-out;
}
nav .container ul li {
  margin-right: 2em;
  padding: 1em;
  white-space: nowrap;
}
nav .container ul li:last-child {
  margin-right: 0em;
  padding-right: 0em;
}
nav .container ul li a {
  text-decoration: none;
  color: #fff;
  opacity: 0.7;
  font-size: 1.5rem;
  font-weight: 500;
}
nav .container ul li a.active {
  opacity: 1;
}
nav .container img {
  min-width: 70px;
  width: 15%;
}
nav .container .burger {
  cursor: pointer;
  display: none;
  z-index: 21;
}
nav .container .burger .line {
  display: block;
  padding: 0.1rem 0;
  background-color: #a0a0a0;
  width: 2.5rem;
  margin-bottom: 0.5em;
  box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.1);
  transition: all 0.5s linear;
}
nav .container .burger .line:last-child {
  margin-bottom: unset;
}
nav .container .burger.clicked .line:first-child {
  transform: rotate(45deg) translateX(50%) translateY(-100%);
}
nav .container .burger.clicked .line:nth-child(2) {
  opacity: 0;
  transform: rotate(45deg) translateX(20%);
}
nav .container .burger.clicked .line:last-child {
  transform: rotate(-45deg) translateX(50%) translateY(100%);
}

@media (max-width: 850px) {
  nav .container .burger {
    display: block;
  }
  nav .container ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0%;
    left: 0;
    background-color: #181818;
    z-index: 20;
    overflow: hidden;
    transform: translateX(100%);
    opacity: 0;
  }
  nav .container ul li {
    margin-right: unset;
    margin-bottom: 3em;
  }
  nav .container ul li:last-child {
    padding-right: 1em;
  }
  nav .container ul.clicked {
    transform: translateX(0%);
    opacity: 1;
  }
}
.landing-page {
  min-height: 100vh;
  background-color: #141414;
  display: flex;
  justify-content: unset;
  align-items: unset;
  flex-direction: column;
  overflow: hidden;
}
.landing-page .landing-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.landing-page .landing-content > div {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: unset;
  position: relative;
}
.landing-page .landing-content > div::after {
  content: "";
  position: absolute;
  height: 80%;
  width: 0.3%;
  background: linear-gradient(to right, #c63d81, #9b2230, #c06a58) no-repeat center/cover;
  background-image: linear-gradient(to bottom, #c63d81, #9b2230, #c06a58);
  border-radius: 1rem;
  right: 0;
  transform: translateX(-50%);
}
.landing-page .landing-intro {
  width: 50%;
}
.landing-page .name-heading {
  display: flex;
  justify-content: unset;
  align-items: start;
  flex-direction: column;
  margin-bottom: 1em 0 1em;
}
.landing-page .name-heading h1,
.landing-page .name-heading h2 {
  background: linear-gradient(to right, #c63d81, #9b2230, #c06a58);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  margin-bottom: 0.2em;
  position: relative;
  font-weight: 700;
  font-size: 5.5rem;
  font-family: "Nunito", "Inter", sans-serif;
  letter-spacing: 5px;
  white-space: nowrap;
}
.landing-page .name-heading h2 {
  margin-bottom: 1em;
  font-weight: 500;
  font-size: 2.5rem;
  letter-spacing: 2px;
}
.landing-page .name-heading h2::after {
  content: "";
  bottom: -10%;
  left: 0;
  position: absolute;
  background: linear-gradient(to right, #c63d81, #9b2230, #c06a58);
  width: 100%;
  height: 10%;
}
.landing-page .landing-intro p {
  font-size: 1.4rem;
  color: #fff;
  font-weight: 500;
  margin-bottom: 2em;
  line-height: 1.5;
}
.landing-page .landing-cta {
  white-space: nowrap;
}
.landing-page .landing-cta button {
  padding: 1em 5em;
  border-radius: 1em;
  cursor: pointer;
}
.landing-page .landing-cta button:hover {
  opacity: 0.8;
}
.landing-page .landing-cta .btn-primary {
  margin-right: 3em;
}
.landing-page .landing-img {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.landing-page .landing-img div {
  padding: 1em;
  background-color: #222;
  border-radius: 50%;
  min-width: 200px;
  width: 50%;
  position: relative;
}
.landing-page .landing-img div img {
  object-fit: cover;
}

.mobile-landing-img {
  display: none;
  margin: 0 auto 2em;
  width: 100%;
}
.mobile-landing-img div {
  padding: 1em;
  background-color: #222;
  border-radius: 50%;
  min-width: 200px;
  width: 50%;
  position: relative;
}
.mobile-landing-img div img {
  object-fit: cover;
}

@media (max-width: 850px) {
  .landing-page .landing-content > div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
  .landing-page .landing-content > div::after {
    display: none;
  }
  .landing-page .landing-intro {
    width: 100%;
    text-align: center;
  }
  .landing-page .landing-intro p {
    font-size: 1.3rem;
  }
  .landing-page .landing-cta {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: unset;
  }
  .landing-page .name-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
  }
  .landing-page .name-heading h1 {
    font-size: 3rem;
  }
  .landing-page .name-heading h2 {
    font-size: 2rem;
  }
  .landing-page .landing-img {
    display: none;
  }

  .mobile-landing-img {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: unset;
  }
  .mobile-landing-img div {
    width: 30%;
  }
}
@media (max-width: 450px) {
  .landing-page .name-heading h1 {
    font-size: 2.5rem;
  }
  .landing-page .name-heading h2 {
    font-size: 1.2rem;
  }
  .landing-page .landing-intro p {
    font-size: 1rem;
  }
  .landing-page .landing-cta {
    flex-direction: column;
  }
  .landing-page .landing-cta .btn-primary {
    margin-right: unset;
    margin-bottom: 1.5em;
  }
}
.profile-page {
  background-color: #141414;
  min-height: 100vh;
  display: flex;
  justify-content: unset;
  align-items: unset;
  flex-direction: column;
  overflow: hidden;
}
.profile-page .landing-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.profile-page .landing-content .container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: unset;
  flex: 1;
}
.profile-page header {
  display: flex;
  justify-content: center;
  align-items: unset;
  flex-direction: column;
}
.profile-page .name-heading {
  display: flex;
  justify-content: unset;
  align-items: start;
  flex-direction: column;
}
.profile-page .name-heading h1,
.profile-page .name-heading h2 {
  background: linear-gradient(to right, #c63d81, #9b2230, #c06a58);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  margin-bottom: 0.2em;
  position: relative;
  font-weight: 700;
}
.profile-page .name-heading h2 {
  margin-bottom: 1em;
  font-weight: 500;
}
.profile-page .name-heading h2::after {
  content: "";
  bottom: -10%;
  left: 0;
  position: absolute;
  background: linear-gradient(to right, #c63d81, #9b2230, #c06a58);
  width: 100%;
  height: 10%;
}
.profile-page .name-photo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: unset;
  width: 70%;
}
.profile-page .name-photo img {
  min-width: 150px;
  width: 20%;
  margin-right: 2em;
}
.profile-page .name-photo div {
  color: #fff;
  font-size: 1rem;
  text-align: left;
  display: flex;
  justify-content: unset;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.profile-page .name-photo div q {
  margin-bottom: 1em;
  font-weight: 100;
}
.profile-page .name-photo div a {
  width: 100%;
  overflow: hidden;
}
.profile-page .name-photo div a .btn-primary {
  width: 100%;
  font-weight: 700;
  border-radius: 6em;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.profile-page .name-photo div a .btn-primary:hover {
  opacity: 0.5;
}

.contact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: unset;
  align-items: center;
  gap: 1.5em;
  grid-row: 2/3;
  color: #fff;
}
.contact * {
  margin-bottom: 0.5em;
}
.contact h3 {
  opacity: 0.6;
  font-weight: 500;
}
.contact p,
.contact li {
  font-weight: 500;
}
.contact ul {
  list-style: none;
}
.contact a {
  color: #fff;
  display: flex;
  align-items: center;
}
.contact a img {
  margin: unset;
  margin-left: 0.4em;
}

.skills {
  display: flex;
  justify-content: center;
  align-items: unset;
  flex-direction: column;
}
.skills h2 {
  color: #fff;
  margin-bottom: 0.5em;
  font-weight: 500;
}
.skills .skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 1em;
  flex-wrap: wrap-reverse;
}
.skills .skills-status {
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  justify-content: end;
  flex-basis: 220px;
}
.skills .skills-img {
  display: flex;
  flex: 1;
  flex-basis: 207px;
  justify-content: start;
}
.skills .skills-img img {
  margin-right: 0.8em;
}
.skills .skills-status h3 {
  margin-left: 0.8em;
  color: #fff;
  opacity: 0.6;
  font-weight: 500;
}
.skills .bar {
  width: 100%;
  height: 10px;
  background-color: #5d5d5f;
  border-radius: 2em;
  margin-bottom: 2em;
}
.skills .white-line {
  background-color: #fff;
  width: 100%;
  height: 10px;
  border-radius: 2em;
}
.skills .white-line.progress {
  width: 50%;
}

.showcase {
  display: flex;
  justify-content: center;
  align-items: unset;
  flex-direction: column;
}
.showcase svg {
  margin-bottom: 4em;
}
.showcase .software {
  display: flex;
  align-items: center;
  justify-content: start;
}
.showcase .software p {
  color: #fff;
  opacity: 0.6;
  margin-right: 1em;
}
.showcase .software a {
  margin-right: 2em;
  min-width: 22px;
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #181818;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 10;
  transition: all 1s ease-in-out;
  opacity: 0.2;
  pointer-events: none;
  transform: translate(100%);
}
.mobile-nav ul {
  list-style: none;
  display: flex;
  flex-direction: column;
}
.mobile-nav ul li {
  margin-bottom: 3em;
}
.mobile-nav a {
  text-decoration: none;
  color: #fff;
  font-size: 2rem;
  font-weight: 500;
}

#white_3 {
  transform-origin: left;
  animation: checklist 15s ease-in-out infinite;
}

#white_2 {
  transform-origin: left;
  animation: checklist2 15s ease-in-out infinite;
}

#white {
  transform-origin: left;
  animation: checklist3 15s ease-in-out infinite;
}

@keyframes checklist {
  10% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(1);
  }
  60% {
    transform: scaleX(1);
  }
  70% {
    transform: scaleX(1);
  }
  80% {
    transform: scaleX(1);
  }
  90% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes checklist2 {
  10% {
    transform: scaleX(0);
  }
  40% {
    transform: scaleX(0.5);
  }
  50% {
    transform: scaleX(0.5);
  }
  60% {
    transform: scaleX(0.5);
  }
  70% {
    transform: scaleX(1);
  }
  80% {
    transform: scaleX(1);
  }
  90% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes checklist3 {
  10% {
    transform: scaleX(0);
  }
  50% {
    transform: scaleX(0.2);
  }
  50% {
    transform: scaleX(0.2);
  }
  60% {
    transform: scaleX(0.2);
  }
  70% {
    transform: scaleX(0.5);
  }
  80% {
    transform: scaleX(0.5);
  }
  85% {
    transform: scaleX(0.5);
  }
  90% {
    transform: scaleX(1);
  }
  95% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1);
  }
}
#Vector_21 {
  animation: transparent 15s linear infinite;
}

#Vector_23 {
  animation: transparent2 15s linear infinite;
}

#Vector_25 {
  animation: transparent3 15s linear infinite;
}

#Vector_31 {
  animation: transparent 15s linear infinite;
}

#Vector_29 {
  animation: transparent2 15s linear infinite;
}

#Vector_30 {
  animation: transparent3 15s linear infinite;
}

@keyframes transparent {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transparent2 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  70% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transparent3 {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  75% {
    opacity: 0.5;
  }
  90% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
#Vector,
#Vector_2,
#Vector_3,
#Vector_6,
#Vector_5,
#Vector_8,
#Vector_9,
#Vector_18 {
  transform-origin: bottom;
  animation: typeing 4s ease-in-out infinite alternate;
}

@keyframes typeing {
  from {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-5deg);
  }
}
#Vector_59,
#Vector_60 {
  transition: transform 1s ease-in-out;
  transform-origin: center bottom;
  transform-box: fill-box;
  animation: tea 3s linear infinite alternate;
}

@keyframes tea {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
#Vector_16,
#Vector_17 {
  transform-origin: center;
  transform-box: fill-box;
  animation: treeshake 2s linear infinite alternate;
}

@keyframes treeshake {
  from {
    transform: rotate(10deg);
  }
  to {
    transform: rotate(-10deg);
  }
}
#Chat {
  transform-origin: left;
  transform-box: fill-box;
  animation: chat 5s linear infinite alternate;
}

@keyframes chat {
  from {
    opacity: 0;
    transform: scaleX(0.5);
  }
  to {
    opacity: 1;
    transform: scaleX(1);
  }
}
@media (max-width: 1500px) {
  .profile-page .name-photo div {
    padding-right: 2em;
  }
}
@media (max-width: 1264px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2rem;
  }
}
@media (max-width: 1200px) {
  .profile-page .name-photo {
    width: 90%;
  }
}
@media (max-width: 1100px) {
  .skills .skills-status {
    justify-content: center;
  }
  .skills .skills-img {
    justify-content: center;
  }
}
@media (max-width: 860px) {
  h1 {
    font-size: 3.5rem;
  }

  h2 {
    font-size: 2rem;
  }
}
@media (max-width: 850px) {
  .profile-page .landing-content .container {
    grid-template-columns: 1fr;
    place-items: center;
    gap: 3em;
  }
  .profile-page .name-photo {
    width: 70%;
  }
  .profile-page .name-photo div {
    padding-right: 0;
  }
  .profile-page .landing-content header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .profile-page .landing-content header a {
    text-decoration: none;
  }

  .contact {
    place-items: center;
  }
}
@media (max-width: 650px) {
  .profile-page .name-photo {
    width: 90%;
  }
}
@media (max-width: 420px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .profile-page .name-photo {
    flex-direction: column;
    justify-content: center;
  }
  .profile-page .name-photo img {
    margin-bottom: 1em;
  }

  .contact {
    grid-template-columns: 1fr;
    place-items: unset;
    padding: 1em;
  }

  .showcase {
    text-align: center;
  }

  .showcase svg {
    max-width: 75%;
  }
}
.project-page {
  min-height: 100vh;
  background-color: #141414;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  z-index: 1;
}
.project-page svg {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  pointer-events: none;
}
.project-page h2 {
  font-weight: 500;
  margin-bottom: 3rem;
  color: #fff;
}
.project-page .project-title {
  background: linear-gradient(to right, #c63d81, #9b2230, #c06a58);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  position: relative;
}
.project-page .project-title::after {
  content: "";
  bottom: -10%;
  left: 0;
  position: absolute;
  background: linear-gradient(to right, #c63d81, #9b2230, #c06a58);
  width: 100%;
  height: 10%;
}
.project-page .html-css-container,
.project-page .javascript-container {
  position: relative;
  margin-bottom: 3em;
}
.project-page .project-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: unset;
  gap: 1.5em;
}
.project-page .project-grid img {
  transition: all 0.2s linear;
}
.project-page .project-grid img:hover {
  transform: scale(1.1);
}

@media (max-width: 850px) {
  .project-page .project-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: unset;
  }
}
@media (max-width: 550px) {
  .project-page .project-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: unset;
    place-items: center;
  }
}