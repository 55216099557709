* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

body,
html {
	animation: onPageLoad 1s ease-in-out forwards;
	font-family: "Inter", "Noto Sans", Ubuntu, Arial, Helvetica, sans-serif;
	scroll-behavior: smooth;
}

body.clicked {
	overflow: hidden;
}

img {
	max-width: 100%;
}

h1 {
	font-size: 4rem;
}

h2 {
	font-size: 3rem;
}

h3 {
	font-size: 1.5rem;
}

.container {
	width: 90%;
	max-width: 1440px;
	margin: 0 auto;
}

.btn-primary {
	padding: 1em 2em;
	font-weight: 700;
	background: $gradient-primary no-repeat center/cover;
	color: $white;
	border: none;
	outline: none;
	text-align: center;
	display: inline-block;

	&.mobile {
		display: none;
	}
}

.btn-secondary {
	padding: 1em 2em;
	font-weight: 700;
	background: $dark-secondary;
	color: $white;
	border: none;
	outline: none;
	text-align: center;
	display: inline-block;
}

.py- {
	&1 {
		padding: 1em 0;
	}

	&2 {
		padding: 2em 0;
	}

	&3 {
		padding: 3em 0em;
	}

	&4 {
		padding: 4em 0;
	}

	&5 {
		padding: 5em 0;
	}

	&6 {
		padding: 6em 0em;
	}
}

@keyframes onPageLoad {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

nav {
	background-color: #181818;
	box-shadow: 0 3px 10px rgba(255, 255, 255, 0.2);
	padding: 0.2em 0;
	width: 100%;
	position: relative;
	z-index: 1;

	.container {
		@include flex(space-between, center, unset);

		ul {
			@include flex(flex-end, center, unset);
			list-style: none;
			transition: all 0.5s ease-in-out;

			li {
				margin-right: 2em;
				padding: 1em;
				white-space: nowrap;

				&:last-child {
					margin-right: 0em;
					padding-right: 0em;
				}

				a {
					text-decoration: none;
					color: #fff;
					opacity: 0.7;
					font-size: 1.5rem;
					font-weight: 500;

					&.active {
						opacity: 1;
					}
				}
			}
		}

		img {
			min-width: 70px;
			width: 15%;
		}

		.burger {
			cursor: pointer;
			display: none;
			z-index: 21;

			.line {
				display: block;
				padding: 0.1rem 0;
				background-color: #a0a0a0;
				width: 2.5rem;
				margin-bottom: 0.5em;
				box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.1);
				transition: all 0.5s linear;
			}

			.line:last-child {
				margin-bottom: unset;
			}

			&.clicked {
				.line:first-child {
					transform: rotate(45deg) translateX(50%) translateY(-100%);
				}
				.line:nth-child(2) {
					opacity: 0;
					transform: rotate(45deg) translateX(20%);
				}
				.line:last-child {
					transform: rotate(-45deg) translateX(50%) translateY(100%);
				}
			}
		}
	}
}

@media (max-width: 850px) {
	nav {
		.container {
			.burger {
				display: block;
			}

			ul {
				display: flex;
				flex-direction: column;
				justify-content: center;
				position: fixed;
				height: 100vh;
				width: 100vw;
				top: 0%;
				left: 0;
				background-color: #181818;
				z-index: 20;
				overflow: hidden;
				transform: translateX(100%);
				opacity: 0;

				li {
					margin-right: unset;
					margin-bottom: 3em;

					&:last-child {
						padding-right: 1em;
					}
				}
			}

			ul.clicked {
				transform: translateX(0%);
				opacity: 1;
			}
		}
	}
}
