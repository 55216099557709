.project-page {
	min-height: 100vh;
	background-color: $bg-main;
	position: relative;
	@include flex(center, center, column);
	overflow: hidden;
	z-index: 1;

	svg {
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0.2;
		pointer-events: none;
	}

	h2 {
		font-weight: 500;
		margin-bottom: 3rem;
		color: $white;
	}

	.project-title {
		background: $gradient-primary;
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		display: inline-block;
		position: relative;

		&::after {
			content: "";
			bottom: -10%;
			left: 0;
			position: absolute;
			background: $gradient-primary;
			width: 100%;
			height: 10%;
		}
	}

	.html-css-container,
	.javascript-container {
		position: relative;
		margin-bottom: 3em;
	}

	.project-grid {
		@include grid(repeat(3, 1fr), unset);
		gap: 1.5em;

		img {
			transition: all 0.2s linear;
		}

		img:hover {
			transform: scale(1.1);
		}
	}
}

@media (max-width: 850px) {
	.project-page {
		.project-grid {
			@include grid(repeat(2, 1fr), unset);
		}
	}
}

@media (max-width: 550px) {
	.project-page {
		.project-grid {
			@include grid(1fr, unset);
			place-items: center;
		}
	}
}
